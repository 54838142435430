@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
	.link {
		@apply flex justify-center items-center gap-3 h-16 rounded-3xl text-xl leading-9;
	}
	.flex-section {
		@apply flex flex-col items-center;
	}
	.hover-btn {
		@apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300;
	}
	.btn-js {
		@apply flex gap-2 bg-bgBody md:w-auto px-5 py-4 rounded-xl text-logo md:text-xl font-bold hover-btn;
	}
}
