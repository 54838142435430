*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::placeholder, textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after, ::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.link {
  border-radius: 1.5rem;
  justify-content: center;
  align-items: center;
  gap: .75rem;
  height: 4rem;
  font-size: 1.25rem;
  line-height: 2.25rem;
  display: flex;
}

.flex-section {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.hover-btn {
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .15s, background-color .3s cubic-bezier(.4, 0, .2, 1) .15s, border-color .3s cubic-bezier(.4, 0, .2, 1) .15s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, fill .3s cubic-bezier(.4, 0, .2, 1) .15s, stroke .3s cubic-bezier(.4, 0, .2, 1) .15s, opacity .3s cubic-bezier(.4, 0, .2, 1) .15s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .15s, transform .3s cubic-bezier(.4, 0, .2, 1) .15s, filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s;
}

.hover-btn:hover {
  --tw-translate-y: -.25rem;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.btn-js {
  transition: color .3s cubic-bezier(.4, 0, .2, 1) .15s, background-color .3s cubic-bezier(.4, 0, .2, 1) .15s, border-color .3s cubic-bezier(.4, 0, .2, 1) .15s, text-decoration-color .3s cubic-bezier(.4, 0, .2, 1) .15s, fill .3s cubic-bezier(.4, 0, .2, 1) .15s, stroke .3s cubic-bezier(.4, 0, .2, 1) .15s, opacity .3s cubic-bezier(.4, 0, .2, 1) .15s, box-shadow .3s cubic-bezier(.4, 0, .2, 1) .15s, transform .3s cubic-bezier(.4, 0, .2, 1) .15s, filter .3s cubic-bezier(.4, 0, .2, 1) .15s, -webkit-backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s, backdrop-filter .3s cubic-bezier(.4, 0, .2, 1) .15s;
}

.btn-js:hover {
  --tw-translate-y: -.25rem;
  --tw-scale-x: 1.1;
  --tw-scale-y: 1.1;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.btn-js {
  --tw-bg-opacity: 1;
  background-color: rgb(2 9 22 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(251 231 182 / var(--tw-text-opacity));
  border-radius: .75rem;
  gap: .5rem;
  padding: 1rem 1.25rem;
  font-weight: 700;
  display: flex;
}

@media (width >= 768px) {
  .btn-js {
    width: auto;
    font-size: 1.25rem;
    line-height: 1.75rem;
  }
}

.order-first {
  order: -9999;
}

.m-5 {
  margin: 1.25rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-10 {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
}

.my-14 {
  margin-top: 3.5rem;
  margin-bottom: 3.5rem;
}

.my-16 {
  margin-top: 4rem;
  margin-bottom: 4rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mb-1 {
  margin-bottom: .25rem;
}

.mb-10 {
  margin-bottom: 2.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mt-8 {
  margin-top: 2rem;
}

.flex {
  display: flex;
}

.grid {
  display: grid;
}

.hidden {
  display: none;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-3 {
  height: .75rem;
}

.h-5 {
  height: 1.25rem;
}

.max-h-\[378px\] {
  max-height: 378px;
}

.w-3 {
  width: .75rem;
}

.w-32 {
  width: 8rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-5 {
  width: 1.25rem;
}

.w-56 {
  width: 14rem;
}

.w-full {
  width: 100%;
}

.max-w-\[327px\] {
  max-width: 327px;
}

.max-w-\[343px\] {
  max-width: 343px;
}

.max-w-\[648px\] {
  max-width: 648px;
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

.animate-pulse {
  animation: 2s cubic-bezier(.4, 0, .6, 1) infinite pulse;
}

.list-none {
  list-style-type: none;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.items-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.gap-0 {
  gap: 0;
}

.gap-0\.5 {
  gap: .125rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-2 {
  gap: .5rem;
}

.gap-3 {
  gap: .75rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.rounded {
  border-radius: .25rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.rounded-md {
  border-radius: .375rem;
}

.rounded-xl {
  border-radius: .75rem;
}

.rounded-t-2xl {
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
}

.rounded-tr-\[200px\] {
  border-top-right-radius: 200px;
}

.border-2 {
  border-width: 2px;
}

.border-b {
  border-bottom-width: 1px;
}

.border-t {
  border-top-width: 1px;
}

.border-gray-800 {
  --tw-border-opacity: 1;
  border-color: rgb(31 41 55 / var(--tw-border-opacity));
}

.border-b-bgBody {
  --tw-border-opacity: 1;
  border-bottom-color: rgb(2 9 22 / var(--tw-border-opacity));
}

.border-t-logo {
  --tw-border-opacity: 1;
  border-top-color: rgb(251 231 182 / var(--tw-border-opacity));
}

.bg-bgBody {
  --tw-bg-opacity: 1;
  background-color: rgb(2 9 22 / var(--tw-bg-opacity));
}

.bg-gray-400\/10 {
  background-color: #9ca3af1a;
}

.bg-gray-700 {
  --tw-bg-opacity: 1;
  background-color: rgb(55 65 81 / var(--tw-bg-opacity));
}

.bg-gray-800 {
  --tw-bg-opacity: 1;
  background-color: rgb(31 41 55 / var(--tw-bg-opacity));
}

.bg-logo {
  --tw-bg-opacity: 1;
  background-color: rgb(251 231 182 / var(--tw-bg-opacity));
}

.bg-white\/\[\.15\] {
  background-color: #ffffff26;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.font-dmSans {
  font-family: DM Sans, sans-serif;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.font-bold {
  font-weight: 700;
}

.leading-8 {
  line-height: 2rem;
}

.tracking-tight {
  letter-spacing: -.025em;
}

.tracking-tighter {
  letter-spacing: -.05em;
}

.tracking-wide {
  letter-spacing: .025em;
}

.text-bgBody {
  --tw-text-opacity: 1;
  color: rgb(2 9 22 / var(--tw-text-opacity));
}

.text-gray-400 {
  --tw-text-opacity: 1;
  color: rgb(156 163 175 / var(--tw-text-opacity));
}

.text-grayText {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.text-logo {
  --tw-text-opacity: 1;
  color: rgb(251 231 182 / var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.shadow-inner {
  --tw-shadow: inset 0 2px 4px 0 #0000000d;
  --tw-shadow-colored: inset 0 2px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:bg-green-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
}

.group:hover .group-hover\:bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}

@media (width >= 768px) {
  .md\:order-1 {
    order: 1;
  }

  .md\:m-10 {
    margin: 2.5rem;
  }

  .md\:my-16 {
    margin-top: 4rem;
    margin-bottom: 4rem;
  }

  .md\:my-28 {
    margin-top: 7rem;
    margin-bottom: 7rem;
  }

  .md\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .md\:mb-20 {
    margin-bottom: 5rem;
  }

  .md\:mt-16 {
    margin-top: 4rem;
  }

  .md\:mt-20 {
    margin-top: 5rem;
  }

  .md\:flex {
    display: flex;
  }

  .md\:w-44 {
    width: 11rem;
  }

  .md\:w-48 {
    width: 12rem;
  }

  .md\:w-56 {
    width: 14rem;
  }

  .md\:w-60 {
    width: 15rem;
  }

  .md\:max-w-5xl {
    max-width: 64rem;
  }

  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }

  .md\:flex-row {
    flex-direction: row;
  }

  .md\:justify-between {
    justify-content: space-between;
  }

  .md\:gap-36 {
    gap: 9rem;
  }

  .md\:gap-4 {
    gap: 1rem;
  }

  .md\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .md\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

@media (width >= 1024px) {
  .lg\:mx-16 {
    margin-left: 4rem;
    margin-right: 4rem;
  }

  .lg\:my-32 {
    margin-top: 8rem;
    margin-bottom: 8rem;
  }

  .lg\:max-h-\[460px\] {
    max-height: 460px;
  }

  .lg\:max-w-\[460px\] {
    max-width: 460px;
  }

  .lg\:max-w-\[752px\] {
    max-width: 752px;
  }

  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .lg\:flex-row {
    flex-direction: row;
  }

  .lg\:gap-10 {
    gap: 2.5rem;
  }
}

/*# sourceMappingURL=index.9ecdb216.css.map */
